
import { useAPI } from "@/use";
import { defineComponent } from "vue-demi";

export default defineComponent({
  name: "LogoutView",
  async created() {
    await useAPI().auth.logoutMethod();
    this.$store.dispatch('logoutAction');
  },
});
